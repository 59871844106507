export function filterData(data, dFields = '', columnCaptions = '') {
  if (!data) return;
  const parsedDisplayFields = dFields.split(',');
  const parsedCaptions = columnCaptions.split(',');
  const matchTruthTable = data.columns.reduce((prev, cur) => {
    const shouldBeKept = parsedDisplayFields.find((dField) => dField === cur.name);

    return [...prev, shouldBeKept ? true : false];
  }, []);
  const validRows = data.data.map((row) => {
    return filterRowCells(row, matchTruthTable);
  });
  const validColumns = data.columns.filter((column, index) => {
    if (matchTruthTable[index]) return column;
    else return false;
  });

  const validColumnsWithUpdatedCaptions = validColumns.map((column, index) => {
    if (parsedCaptions[index]) return { ...column, caption: parsedCaptions[index] };
    else return column;
  });

  const validColumnsWithId = [{ name: 'ID' }, ...validColumnsWithUpdatedCaptions];

  return { columns: validColumnsWithId, data: validRows };
}

function filterRowCells(row, matchTruthTable) {
  const idCOlumn = row[0];
  const filteredRowCells = row.filter((rowCell, index) => {
    if (matchTruthTable[index]) {
      return true;
    }
    return false;
  });
  return [idCOlumn, ...filteredRowCells];
}

export function getListValue(data, id) {
  if (!data || !id) return '';
  const match = data.find(([itemId]) => itemId === id || Number(itemId) === id);
  if (!match) return '';
  const description = match[1];
  return description;
}
