import styled from 'styled-components/macro';

import { ReactComponent as Check } from '../../assets/icons/interface.svg';
import { ReactComponent as Error } from '../../assets/icons/signs.svg';
import { device } from '../../config/device';

export const CheckIcon = styled(Check)`
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  padding: 1.5rem;
  border: 2px solid ${({ theme }) => theme.green};
  fill: ${({ theme }) => theme.green};
`;
export const ErrorIcon = styled(Error)`
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  padding: 1.5rem;
  border: 2px solid ${({ theme }) => theme.error};
  fill: ${({ theme }) => theme.error};
`;

export const FinalScreenContainer = styled.div`
  border-radius: 5px;
  display: flex;
  min-height: 50rem;
  height: 100%;
  margin: 4rem auto;
  display: flex;
  box-shadow: 0 0 12px -1px rgba(0, 0, 0, 0.1), 0 0px 12px -1px rgba(0, 0, 0, 0.06);
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 4rem;
  overflow: hidden;
  @media ${device.tablet} {
    flex: 1;
  }
  span,
  p {
    margin-top: 4rem;
    text-align: center;
    color: ${({ theme }) => theme.tertiary};
  }
  p {
    width: 100%;
    font-weight: 600;
    word-wrap: break-word;
    margin-bottom: 2rem;
  }
  span {
    font-weight: 900;
    font-size: ${(props) => props.theme.xx_large};
  }
`;
export const ImageWrapper = styled.div`
  min-width: 40%;
  background: ${({ theme }) => `linear-gradient(${theme.special}, ${theme.special}80)`},
    url(https://cutewallpaper.org/21/particles-wallpapers/Particles-Wallpapers-Top-Free-Particles-Backgrounds-.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${device.tablet} {
    min-height: 20rem;
  }
`;

export const StyledButton = styled.button`
  padding: 1.5rem 4rem;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.special};
  color: #fff;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: auto;
  :hover {
    opacity: 0.9;
  }
`;
