export const Version = '2.13.4';
export function saveVersion() {
  localStorage.setItem('portal-version', Version);
}

export function getLastSavedVersion() {
  return localStorage.getItem('portal-version');
}

export function isLastSavedVersionLatest() {
  const latestSavedVersion = getLastSavedVersion();
  if (!latestSavedVersion || Version === latestSavedVersion) return true;
  return false;
}
