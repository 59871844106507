import { createGlobalStyle } from 'styled-components';

import { device } from './device';
import defaultTheme from './themes';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }
  html {
    height:100%;
    font-size: 62.5%;
    background-color: ${(props) => props.theme.grayLight};
    @media ${device.mobile} {
      font-size: 56.8%;
    }
    @media ${device.tablet} {
      font-size: 58.8%;
    }
  }
  body {
      margin-top: 10rem;
      font-weight: 500;
      font-size: 1.6rem;
      box-sizing: border-box;
      color: ${defaultTheme.tertiary};
      font-family: "Roboto",'Open Sans' ,sans-serif;
      overflow: hidden;
      #root {
        height: calc(100vh - 10rem);
        /* min-height: calc(100vh - 14rem); */
        overflow: auto;
      }
  }
  *::-webkit-scrollbar {
        width: 0.75rem;
        height: 0.75rem;
    }
  *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.grayLight};
  }
  *::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.gray2};
  }
`;

export default GlobalStyle;
