import PropagateLoader from 'react-spinners/ScaleLoader';

import { LoadingContainer } from './Loading.style.js';

export default function Loading() {
  return (
    <LoadingContainer>
      <PropagateLoader
        height={60}
        margin={3}
        width={8}
        radius={2}
        color='rgb(68,69,77,0.8)'
        loading={true}
      />
    </LoadingContainer>
  );
}
