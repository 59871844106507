import { SoftOneBoolean } from '../helpers/helpers';

export const publicWindow = window['runConfig'];

export function isCopyIconVisible(objectName, copyIcon, excludeFromCopyIcon) {
  if (!SoftOneBoolean(copyIcon)) return false;
  if (!excludeFromCopyIcon || !excludeFromCopyIcon.length)
    return SoftOneBoolean(copyIcon);
  return excludeFromCopyIcon.every((OBJ) => OBJ !== objectName);
}
