const size = {
  mobile: '475px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1440px',
  lDesktop: '2560px',
  xlDesktop: '3264px',
};

const deviceNumberSizes = {
  mobile: 475,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
  lDesktop: 2560,
  xlDesktop: 3264,
};

const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
  lDesktop: `(max-width: ${size.lDesktop})`,
  xlDesktop: `(max-width: ${size.xlDesktop})`,
};

export { device, deviceNumberSizes };
