import {
  getDataForSearchFields,
  getDataForStringListFields,
} from '../getDataForSpecialFields';
import { customAxios } from '../helpers';

async function calculate(object, fieldsData, key, locateObject) {
  const { locateInfo, searchInfo, stringListInfo } = locateObject;
  const response = await customAxios({
    service: 'calculate',
    OBJECT: object.OBJSOFTONENAME,
    FORM: object.OBJSOFTONEFORM || '',
    data: fieldsData,
    LOCATEINFO: locateInfo,
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData.error);
  const calculateData = responseData.data;
  const dataForSearchFields = await getDataForSearchFields(calculateData, searchInfo);
  const dataForStringListFields = await getDataForStringListFields(
    calculateData,
    stringListInfo,
  );

  return {
    editData: calculateData,
    dataForSpecialFields: { dataForSearchFields, dataForStringListFields },
  };
}

async function changePassword({ oldPassword, newPassword }) {
  const result = await customAxios({
    service: 'changePassword',
    OLD: oldPassword,
    NEW: newPassword,
  });
  if (!result.data.success) {
    throw new Error('Softone Issue with changing password');
  }
  return result;
}

async function setData(object, data, key) {
  const request = {
    KEY: key,
    service: 'setData',
    OBJECT: object.OBJSOFTONENAME,
    FORM: object.OBJSOFTONEFORM || '',
    data,
  };

  const result = await customAxios(request);
  return result;
}

async function getInfoAndBrowserData(
  key,
  { object, list, filters, query = '', softoneTopLimit },
) {
  const infoResponse = await customAxios({
    service: 'getBrowserInfo',
    OBJECT: object || '',
    LIST: list || '',
    FILTERS: filters
      ? `SELECTTOP=${softoneTopLimit}&SEARCH=` + query + '&FILTERS=&' + filters
      : `SELECTTOP=${softoneTopLimit}&SEARCH=` + query,
  });
  const infoResult = infoResponse.data;
  if (!infoResult.success) {
    throw new Error(infoResult.error);
  }
  if (infoResult.totalcount === 0) {
    return { data: [], infoData: [] };
  }
  const browserResponse = await customAxios({
    service: 'getBrowserData',
    reqID: infoResult.reqID,
    START: 0,
    LIMIT: softoneTopLimit,
  });
  const browserResult = browserResponse.data;
  if (!browserResult.success) {
    throw new Error(browserResult.error);
  }
  const columnsWithSoftoneFieldsType = infoResult.columns.reduce((prev, cur, index) => {
    return [...prev, { ...cur, type: infoResult.fields[index + 1].type }];
  }, []);
  return { data: browserResult.rows, infoData: columnsWithSoftoneFieldsType };
}

async function getSelectorStringData(key, { editor }) {
  const response = await customAxios({
    service: 'getSelectorData',
    EDITOR: editor,
  });
  let data = response.data;
  //megali kagkouria logw stringlist softone
  data = data.replace('model":,', 'model":"",');
  data = JSON.parse(data);
  data.model = [{ name: 'CODE' }, { name: 'DESCRIPTION' }];
  data.totalCount = data.data.length || 0;
  if (!data.success) throw new Error(data.error);
  if (data.totalcount === 0) {
    return { columns: [], data: [] };
  }
  if (data.data.length === 0) {
    throw new Error('No records found!');
  }

  return { columns: data.model, data: data.data, editor };
}

async function getSelectorData(key, { editor }) {
  const response = await customAxios({
    service: 'getSelectorData',
    EDITOR: editor,
  });
  let data = response.data;
  if (!data.success) throw new Error(data.error);
  if (data.totalcount === 0) {
    return { columns: [], data: [] };
  }
  if (data.data.length === 0) {
    throw new Error('No records found!');
  }
  return { columns: data.model, data: data.data };
}

async function getData({ object, form, key, locateInfo, fieldName }) {
  const response = await customAxios({
    service: 'getData',
    OBJECT: object,
    FORM: form || '',
    LOCATEINFO: locateInfo,
    KEY: key,
  });
  const data = response.data;
  return { ...data, fieldName };
}

async function getEditDataWithKey(key, { form, object, fieldValue, locateObject }) {
  return await getEditData({ object, form, key: fieldValue, locateObject });
}

async function getEditData({ object, form, key, locateObject, copy = false }) {
  const { locateInfo, searchInfo, stringListInfo } = locateObject;
  const response = await customAxios({
    service: 'getData',
    OBJECT: object || '',
    FORM: form || '',
    KEY: key,
    LOCATEINFO: locateInfo,
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData.error);
  /* second response */
  const editData = responseData.data;
  const dataForSearchFields = await getDataForSearchFields(editData, searchInfo);
  const dataForStringListFields = await getDataForStringListFields(
    editData,
    stringListInfo,
  );

  return {
    editData,
    dataForSpecialFields: { dataForSearchFields, dataForStringListFields },
    //if we use the copy flag , we dont edit the form , we create a new object
    key: copy ? null : key,
  };
}

async function getSqlData(key, { SqlName, sqlFilters }) {
  const uncompletedRelatedFields = sqlFilters.filter(
    (sqlFilter) => !sqlFilter.value.length > 0,
  );
  if (uncompletedRelatedFields.length > 0) {
    throw new Error('Related values not completed');
  }
  const response = await customAxios({
    service: 'SqlData',
    SqlName: SqlName,
    ...sqlFilters.reduce((acc, curr) => {
      return { ...acc, [curr.key]: curr.value };
    }, {}),
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData.errorcode);
  return responseData.rows;
}

async function getPdf({ object, key, template = {}, printTemplate }) {
  const response = await customAxios({
    service: 'printDoc',
    OBJECT: object,
    KEY: key,
    TEMPLATE: template.TEMPLTEID || printTemplate,
    FORMAT: 'PDF',
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData?.errorcode);
  return { fileName: responseData.filename, caption: template.TEMPLTECAPTION };
}

export {
  getPdf,
  calculate,
  setData,
  getInfoAndBrowserData,
  getSelectorData,
  getSelectorStringData,
  getEditData,
  getSqlData,
  getData,
  getEditDataWithKey,
  changePassword,
};
