import { lazy, Suspense } from 'react';

import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import ErrorBoundary from './components/Error/ErrorBoundary';
import Footer from './components/Footer/Footer';
import Loading from './components/Loading/Loading';
import NavBar from './components/Navbar/NavBar';
import { Layout } from './components/shared/Layouts/Layout';
import GlobalStyle from './config/GlobalStyle';
import { useUserConfiguration } from './config/themes';
import { useAuth } from './context/AuthProvider';
import { ModalProvider } from './context/ModalProvider';

const UnauthenticatedApp = lazy(() => {
  return import('./components/Auth/UnauthenticatedApp');
});
const AuthenticatedApp = lazy(() => {
  return import('./components/Auth/AuthenticatedApp');
});

function App() {
  const authState = useAuth();
  const { theme, title } = useUserConfiguration();
  const iAuthorized = authState.matches('authorized');
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <NavBar />
          <ModalProvider>
            <Layout>
              <Suspense fallback={<Loading />}>
                {iAuthorized ? <AuthenticatedApp /> : <UnauthenticatedApp />}
              </Suspense>
            </Layout>
            <Footer />
          </ModalProvider>
        </ErrorBoundary>
        <GlobalStyle />
      </ThemeProvider>
    </>
  );
}

export default App;
