import { motion } from 'framer-motion';
import styled from 'styled-components';

import { device } from '../../config/device';

export const NavMenuWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
  .close-icon {
    fill: white;
    height: 25px;
    cursor: pointer;
    z-index: 300;
    width: 25px;
    right: 15px;
    top: 30px;
    position: absolute;
  }
  .menu-hamburger-icon {
    svg {
      width: 30px;
      height: 30px;
      fill: #ecebf2;
      margin-right: 10px;
      cursor: pointer;
    }
  }
`;

export const NavStaticLinkWrapper = styled.li`
  display: flex;
  cursor: pointer;
  font-size: ${({ theme }) => theme.small};
  align-items: center;
  .menu-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    fill: currentColor;
  }
`;

export const SidebarOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  background: black;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  opacity: 0.7;
`;

export const SidebarContent = styled.div`
  color: white;
  position: relative;
  padding: 55px 35px;
`;

export const SidebarContainer = motion.custom(styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  z-index: 30;
  background: ${({ theme }) => theme.text};
  right: 0;
`);

export const NavlinksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  li {
    width: max-content;
    margin-bottom: 20px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  .nav-link {
    padding-left: 0.5rem;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    font-size: ${({ theme }) => theme.medium};
    color: ${({ theme }) => theme.gray};
    &:hover {
      color: ${({ theme }) => `${theme.gray}CC`};
    }
  }
  .nav-icon {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    fill: currentColor;
  }
`;
