import { v4 as uuidv4 } from 'uuid';

import { getListValue } from '../../../components/List/List.utils';
import { isObjEmpty } from '../../../helpers/helpers';
import { createDetailGroups } from './utils';

function getSearchDisplayValue(displayField, searchValues) {
  if (displayField.split(',').length === 1) {
    const [name] = displayField.split(',');
    if (!(name in searchValues)) return '';
    return `${searchValues[name]}`;
  }
  const [code, name] = displayField.split(',');
  if (!(code in searchValues) || !(name in searchValues)) return '';

  return `${searchValues[code]} | ${searchValues[name]}`;
}
function getValuesAccordingToType(item, value) {
  if (
    item.FIELDTYPE === 'Date' ||
    item.FIELDTYPE === 'DateTime' ||
    item.FIELDTYPE === 'Time'
  ) {
    const displayValue = Boolean(value) === true ? new Date(value) : undefined;
    return { fieldValue: value, displayValue };
  }
  if (item.FIELDTYPE === 'List' || item.FIELDTYPE === 'Hidden') {
    if (typeof value === 'string') {
      const fieldValue = value.split('|').shift().trim();
      return { fieldValue, displayValue: value };
    }
  }
  if (item.FIELDTYPE === 'Text') {
    if (item.FIELDPROPERTY !== 'Text') {
      return {
        fieldValue: value.replace('.', ','),
        displayValue: value.replace('.', ','),
      };
    }
  }
  return { fieldValue: value, displayValue: value };
}

export function createNewItem({ item, index, eventData }) {
  const { editData, dataForSpecialFields } = eventData;

  const [objectName, tableName] = item.FIELDNAME.split('.');
  if (!(objectName in editData)) return item;
  const editValues = editData[objectName][index];
  if (!editValues || !(tableName in editValues)) return item;

  // ------ Serch fields ------
  if (item.FIELDTYPE === 'Search') {
    const { dataForSearchFields } = dataForSpecialFields;
    if (isObjEmpty(dataForSearchFields)) return item;
    if (!(tableName in dataForSearchFields)) return item;
    const searchValues = dataForSearchFields[tableName][index];
    if (!searchValues) return item;
    return {
      ...item,
      FIELDVALUE: editValues[tableName],
      DISPLAYVALUE: getSearchDisplayValue(item.FIELDDISPLAY, searchValues),
    };
  }
  // only search for detail level cause we handle stringList Fields on other levels
  if (item.FIELDTYPE === 'String') {
    const { dataForStringListFields } = dataForSpecialFields;
    if (isObjEmpty(dataForStringListFields)) return item;
    if (!(item.FIELDEDITOR in dataForStringListFields)) return item;
    const stringListValues = dataForStringListFields[item.FIELDEDITOR];
    if (!stringListValues) return item;
    return {
      ...item,
      FIELDVALUE: editValues[tableName],
      DISPLAYVALUE: getListValue(stringListValues.data, editValues[tableName]),
    };
  }

  // ----- else ------
  const { fieldValue, displayValue } = getValuesAccordingToType(
    item,
    editValues[tableName],
  );
  return { ...item, FIELDVALUE: fieldValue, DISPLAYVALUE: displayValue };
}

function updateLinesWithRest(newLines, rest) {
  return newLines.map((storeLine) => {
    for (let i = 0; i < rest.length; i++) {
      const { item, index } = rest[i];
      storeLine.item.splice(index, 0, item);
    }
    return { ...storeLine, item: storeLine.item };
  });
}
function createNewLines(storeConfig, eventData) {
  const rest = [];
  const newLines = [];
  storeConfig.forEach((item, index) => {
    if (!item.FIELDNAME) {
      return rest.push({ item, index });
    }
    const [objectName] = item.FIELDNAME.split('.');
    const detailArray = eventData.editData[objectName];
    if (!detailArray) {
      return rest.push({ item, index });
    }
    if (newLines.length === 0) {
      for (let i = 0; i < detailArray.length; i++) {
        newLines[i] = { id: uuidv4(), item: [] };
      }
    }
    for (let i = 0; i < detailArray.length; i++) {
      const newItem = createNewItem({ item, index: i, eventData });
      newLines[i].item.push(newItem);
    }
  });
  return updateLinesWithRest(newLines, rest);
}

export function updateConfigWithEditData(config, eventData) {
  return config.map((item) => {
    if (!item.FIELDNAME) return item;
    return createNewItem({ item, index: 0, eventData });
  });
}
export function updateStoreWithEditData(config, eventData) {
  const detailGroups = createDetailGroups(config);
  const detailGroupsEntries = Object.entries(detailGroups);

  return detailGroupsEntries.reduce((newObj, [name, storeConfig]) => {
    const newLines = createNewLines(storeConfig, eventData);
    return { ...newObj, [name]: newLines };
  }, {});
}
export function updateStoreWithCalcData(ctx, eventData) {
  return ctx.details.reduce((prev, { name, config }) => {
    const newLines = createNewLines(config, eventData);
    const updatedLines = newLines.map((line) => {
      return { ...line, item: line.item };
    });
    return { ...prev, [name]: updatedLines };
  }, {});
}
export function updateDraftWithEditData(eventData) {
  const foundItem = eventData.draft.find((item) => item.FIELDNAME);
  const [tableName] = foundItem.FIELDNAME.split('.');

  const prevArray = eventData.prevData[tableName];
  if (!prevArray.length) return eventData.draft;

  const { LINENUM } = prevArray[prevArray.length - 1];
  const index = eventData.editData[tableName].findIndex((item) => {
    return Number(item.LINENUM) === Number(LINENUM);
  });

  return eventData.draft.map((item) => {
    if (!item.FIELDNAME) return item;
    return createNewItem({ item, index, eventData });
  });
}
