import { useMemo, useEffect, createContext, useContext } from 'react';

import { isLastSavedVersionLatest } from '../config/Version';
import useInterceptor from '../hooks/useInterceptor';
import usePerstistedMachine from '../hooks/usePerstistedMachine';
import { AuthMachine } from '../machines/AuthMachine/AuthMachine';
const AuthContext = createContext();

const AuthDispatchContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, send] = usePerstistedMachine('portal-authMachine', AuthMachine);
  const { user } = state?.context;

  // memoized user object
  const memoUser = useMemo(() => {
    return user;
  }, [user]);

  useInterceptor(memoUser, send);
  //refresh token on every mount or logout when new is Version is updated
  useEffect(() => {
    const isLatestVersion = isLastSavedVersionLatest();
    if (!isLatestVersion) send('LOGOUT');
    else send('REFRESH_TOKEN');
  }, [send]);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={send}>{children}</AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth, useAuthDispatch };
