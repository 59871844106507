import { assign, createMachine } from 'xstate';

import { initializeMachine } from './actors/intializeMachine';

const initialContext = {
  user: undefined,
  config: [],
  objects: [],
  branchConfig: {},
  expDate: undefined,
  error: '',
};

function getSelectedObj(objs, selectedItems) {
  return objs.filter((obj) =>
    Object.keys(selectedItems).every((key) => selectedItems[key] === obj[key]),
  );
}

const AuthMachine = createMachine(
  {
    id: 'authMachine',
    initial: 'unothorized',
    context: { ...initialContext },
    states: {
      unothorized: {
        on: {
          AUTHORIZE: {
            target: 'authorized',
            actions: ['assignUserInfo', 'assignBranchInfo', 'refreshExpDate'],
          },
        },
      },
      authorized: {
        exit: 'resetContext',
        initial: 'waitingInterceptor',
        states: {
          waitingInterceptor: {
            on: {
              APPROVAL: 'initializing',
            },
          },
          initializing: {
            invoke: {
              id: 'initialize',
              src: initializeMachine,
              data: (ctx) => ({ ...initializeMachine.context, user: ctx.user }),
              onDone: [
                {
                  cond: 'isChildSuccessFul',
                  target: 'initialized',
                  actions: 'assignInitializeData',
                },
                {
                  target: 'initializingFailure',
                  actions: 'assignInitializeError',
                },
              ],
            },
          },
          initializingFailure: {
            on: {
              RETRY: {
                target: 'initializing',
              },
            },
          },
          initialized: {},
        },
        on: {
          CHANGE_BRANCH: {
            target: '.initializing',
            actions: ['assignNewClientID', 'assignBranchInfo', 'refreshExpDate'],
          },
          LOGOUT: {
            target: '#authMachine.unothorized',
            actions: 'resetContext',
          },
          REFRESH_TOKEN: [
            {
              cond: 'tokenExpired',
              target: '#authMachine.unothorized',
            },
            { actions: 'refreshExpDate' },
          ],
        },
      },
    },
  },
  {
    actions: {
      assignNewClientID: assign((ctx, e) => {
        return { ...ctx, user: { ...ctx.user, clientID: e.clientID } };
      }),
      assignBranchInfo: assign((ctx, e) => {
        const { objs, selected } = e.branch;
        const [selectedObj] = getSelectedObj(objs, selected);
        return { ...ctx, branch: { objs, selected: selectedObj } };
      }),
      assignInitializeError: assign((ctx, e) => {
        const { error } = e.data;
        return { ...ctx, error: error.message };
      }),
      assignInitializeData: assign((ctx, e) => {
        const { config, objects, branchConfig } = e.data;
        return { ...ctx, config, objects, branchConfig };
      }),
      assignUserInfo: assign((ctx, e) => {
        return { ...ctx, user: e.user };
      }),
      refreshExpDate: assign((ctx) => {
        const expDate = new Date();
        expDate.setDate(new Date().getDate() + 1);
        return { ...ctx, expDate };
      }),
      resetContext: assign(() => {
        return { ...initialContext };
      }),
    },
    guards: {
      tokenExpired: (ctx) => {
        return new Date(ctx.expDate) - new Date() < 0;
      },
      isChildSuccessFul: (_, e) => {
        return !e.data.error;
      },
    },
  },
);

export { AuthMachine };
