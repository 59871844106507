import { useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { deviceNumberSizes } from '../../config/device';
import { BranchWrapper } from './NavBar.style';
import {
  NavlinksWrapper,
  NavMenuWrapper,
  NavStaticLinkWrapper,
  SidebarContainer,
  SidebarContent,
  SidebarOverlayContainer,
} from './NavMenu.style';
import NavMenuLink from './NavMenuLink';

const variants = {
  open: (width) => ({
    opacity: 1,
    width: width > deviceNumberSizes.mobile ? '60vw' : '100vw',
  }),

  closed: () => ({
    opacity: 0,
    width: 0,
  }),
};

function AccountMenu({ user, branch, logout, toggleMenu }) {
  const MENUOPTIONS = [
    {
      TITLE: (
        <BranchWrapper>
          <p>
            {user.username} ({branch.selected.REFIDNAME})
          </p>
          <span>{branch.selected.MODULENAME}</span>
        </BranchWrapper>
      ),
    },
    {
      TITLE: (
        <BranchWrapper>
          <p>{branch.selected.COMPANYNAME}</p>
          <span>{branch.selected.BRANCHNAME}</span>
        </BranchWrapper>
      ),
    },
    {
      HIDDEN: branch.objs.length <= 1,
      TITLE: 'Change Company',
      PATH: 'branch-selection',
    },
    {
      TITLE: 'Change Password',
      PATH: 'change-password',
    },
  ];
  const object = { OBJECTNAME: 'Account', MENUOPTIONS };
  return (
    <>
      <NavMenuLink closeMenu={toggleMenu} object={object} />
      <NavStaticLinkWrapper onClick={logout}>
        Logout
        <Logout className='menu-icon' />
      </NavStaticLinkWrapper>
    </>
  );
}

function NavMenu({ objects, user, branch, handleLogout }) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenu() {
    setIsOpen((prev) => !prev);
  }

  return (
    <NavMenuWrapper>
      <div className='menu-hamburger-icon' onClick={toggleMenu}>
        <svg viewBox='0 0 100 80' width='40' height='40'>
          <rect width='100' height='12'></rect>
          <rect y='30' width='100' height='12'></rect>
          <rect y='60' width='100' height='12'></rect>
        </svg>
      </div>
      {/* animation fix workaround */}
      {isOpen && (
        <SidebarOverlayContainer
          key='overlay'
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}
      <AnimatePresence>
        {isOpen && (
          <div onClick={() => {}}>
            <SidebarContainer
              initial='closed'
              exit='closed'
              animate={isOpen ? 'open' : 'closed'}
              variants={variants}
              transition={{ duration: 0.3 }}
              custom={window.innerWidth}
            >
              <SidebarContent>
                <CloseIcon className='close-icon' onClick={toggleMenu} />
                <NavlinksWrapper>
                  <NavStaticLinkWrapper
                    onClick={() => {
                      toggleMenu();
                      history.push('/');
                    }}
                  >
                    Home
                    <HomeIcon className='menu-icon' />
                  </NavStaticLinkWrapper>
                  {objects.map((object) => (
                    <NavMenuLink
                      closeMenu={toggleMenu}
                      object={object}
                      key={object.OBJECTPATH}
                    />
                  ))}
                  <AccountMenu
                    logout={() => {
                      toggleMenu();
                      handleLogout();
                    }}
                    {...{ user, branch, toggleMenu }}
                  />
                </NavlinksWrapper>
              </SidebarContent>
            </SidebarContainer>
          </div>
        )}
      </AnimatePresence>
    </NavMenuWrapper>
  );
}

export default NavMenu;
