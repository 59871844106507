import { RiAccountCircleFill, RiKeyFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ReactComponent as ArrowDown } from '../../assets/icons/cheveron-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/cheveron-up.svg';
import { ReactComponent as Link } from '../../assets/icons/link.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { device } from '../../config/device';
import { iconStyle } from '../shared/icons/ActionIcons';

export const AccountIcon = styled(RiAccountCircleFill)`
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
`;
export const LogoutIcon = styled(Logout)`
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
  fill: ${(props) => props.theme.gray2} !important;
`;
export const KeyIcon = styled(RiKeyFill)`
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
  fill: ${(props) => props.theme.gray2} !important;
`;
export const LinkIcon = styled(Link)`
  height: 1.5rem;
  width: 1.5rem;
  min-height: 1.5em;
  min-width: 1.5rem;
  flex-shrink: 0;
  margin-left: 1rem;
  fill: ${(props) => props.theme.gray2} !important;
`;

//////////////////////////////////////////////

export const StyledArrowUp = styled(ArrowUp)`
  ${iconStyle}
  min-height: 2em;
  min-width: 2rem;
  margin-left: 1rem;
  fill: #fff;
`;
export const StyledArrowDown = styled(ArrowDown)`
  ${iconStyle}
  min-height: 2em;
  min-width: 2rem;
  margin-left: 1rem;
  fill: #fff;
`;

export const BranchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: ${(props) => props.theme.gray};
  }
`;

export const StyledLink = styled(NavLink)`
  flex-wrap: no-wrap;
`;
export const NavLogo = styled.img`
  flex-shrink: 0;
  cursor: pointer;
  height: ${({ logoHeight }) => logoHeight};
`;

export const NavContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
`;
export const NavbarContainer = styled.nav`
  height: 10rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  background-color: ${(props) => props.theme.tertiary};
`;
export const NavLinkWrapper = styled.div`
  height: 100%;
  z-index: 100;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
  color: ${(props) => props.theme.primary};
  svg {
    fill: ${(props) => props.theme.primary};
  }
  :hover {
    svg {
      fill: ${(props) => props.theme.primary};
    }
  }
  a {
    height: 100%;
    color: #fff;
    opacity: 1;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-weight: 500;
    text-decoration: none;
    font-size: ${(props) => props.theme.small};
    :last-child {
      margin-right: 0;
    }
  }
  .activeLink {
    :before {
      content: '';
      position: absolute;
      top: 0;
      z-index: -1;
      height: 80%;
      width: 100%;
      transform-origin: top;
      border-radius: 0px 0px 15px 15px;
      background-color: ${(props) => props.theme.special};
      box-shadow: 0px 1px 5px 0px ${(props) => props.theme.special};
      animation: onActive 0.3s ease-in-out;
    }
    @keyframes onActive {
      from {
        transform: scaleY(0);
      }
      to {
        transform: scaleY(1);
      }
    }
  }
  @media ${device.tablet} {
    display: none;
  }
`;
export const DropList = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 75%;
  z-index: 2;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.grayLight};
  box-shadow: 0px 0px 2px 0px ${(props) => props.theme.gray2};
  border-radius: 3px;
  :before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    z-index: 3;
    right: 2.4rem;
    top: -0.5rem;
    transform: rotate(45deg);
    background-color: ${(props) => props.theme.grayLight};
    box-shadow: -1px -1px 2px -1px ${(props) => props.theme.gray2};
  }
`;
export const Label = styled.div`
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: anywhere;
  color: ${(props) => props.theme.gray2};
  border-bottom: 1px solid ${(props) => `${props.theme.gray2}66`};
  pointer-events: ${({ hasIcon }) => (hasIcon ? 'auto' : 'none')};
  :last-child {
    border-bottom: none;
  }
  :hover {
    opacity: 0.7;
  }
`;
export const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
`;
