import { createContext, useContext, useRef } from 'react';

import { useOnClickOutsideModal } from '../hooks/useOnClickOutsideModal';
export const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const openModals = useRef([]);
  return (
    <ModalContext.Provider value={openModals.current}>{children}</ModalContext.Provider>
  );
};
const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};
export const useModalTrack = () => {
  let openModals = useModalContext();
  useOnClickOutsideModal();
  function subscribe({ id, handleClose, ref, fullScreen }) {
    const found = openModals.find((modal) => modal.id === id);
    if (found) return;
    openModals.push({ id, close: handleClose, ref, fullScreen });
  }
  function unSubscribe() {
    openModals.pop();
  }
  return { subscribe, unSubscribe };
};

export { ModalProvider, useModalContext };
