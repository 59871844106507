import axios from 'axios';

import { API } from '../config/API';

export const identifyType = (object, key) => {
  /* return true if one item in the array satisfy the statement */
  return Object.keys(object).some((groupKey) => groupKey === key);
};

export const getIconLibrary = (name = '') => {
  const library = name.substring(0, 2);
  return library.toLowerCase();
};

export const customAxios = async (requestObject) => {
  return axios.post(API, {
    ...requestObject,
  });
};

export const groupBy = (key) => (array) =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    }),
    {},
  );

function requiredItemStatus(item) {
  return item.FIELDVALUE && item.DISPLAYVALUE
    ? { status: 'success' }
    : { status: 'error' };
}
function defaulItemStatus(item) {
  return item.FIELDVALUE && item.DISPLAYVALUE
    ? { status: 'success' }
    : { status: 'default' };
}
export function getBaseStatusInformation(item) {
  if (item.FIELDREQUIRED) return requiredItemStatus(item);
  else return defaulItemStatus(item);
}

export function isObjEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
  }
  return true;
}
export function excludeFromObj(currObj, arr) {
  const newObj = { ...currObj };
  arr.forEach((prop) => delete newObj[prop]);
  return newObj;
}

export function getEopyyPatientCode(config) {
  const collaborationCenterMatch = config.find((item) => {
    return item?.FIELDNAME === 'SALDOC.CCCPATIENT';
  });
  if (collaborationCenterMatch?.FIELDVALUE) return collaborationCenterMatch.FIELDVALUE;
  const customerMatch = config.find((item) => {
    return item?.FIELDNAME === 'SALDOC.TRDR';
  });

  return customerMatch?.FIELDVALUE;
}

export function parseObjectField(fieldame) {
  if (!fieldame) return null;
  const objectField = fieldame.split('.')[1];
  return objectField;
}

export function SoftOneBoolean(value) {
  if (value === '0') return false;
  if (value === 'false') return false;
  return Boolean(value);
}

export function getFileGroups(pages = []) {
  const fileGroups = pages.reduce((groups, currentPage) => {
    const currentPageFileGroups = currentPage.GROUPS.filter(
      (group) => group.GROUPLEVEL === 'File',
    );
    return [...groups, ...currentPageFileGroups];
  }, []);
  return fileGroups;
}
export function getFileTableNamesFromGroupsArray(groups = []) {
  const groupByFileObject = groupBy('GROUPFILEOBJ');
  return Object.keys(groupByFileObject(groups));
}
export function getFileTableNamesFromObject(object) {
  const groups = getFileGroups(object.PAGES);
  return getFileTableNamesFromGroupsArray(groups);
}
export function getFilesConfig(object, data) {
  if (!data || !object) return [];
  const tableNames = getFileTableNamesFromObject(object);
  const config = tableNames.reduce((previousConfig, currentTableName) => {
    const currentTableFiles = data[currentTableName];
    if (!currentTableFiles) return previousConfig;
    const transformedCurrentTableFiles = currentTableFiles.map((currentTableFile) => ({
      name: currentTableFile.NAME,
      date: currentTableFile.CRTDATE,
      serverPath: currentTableFile.SOFNAME,
      basePath: currentTableFile.CODE,
      softoneSubmitKey: currentTableName,
    }));
    return [...previousConfig, ...transformedCurrentTableFiles];
  }, []);
  return config;
}

export function parseValuesBetweenPairOfSymbols(symbol1, symbol2) {
  return (string) => {
    const splits = string.split(symbol1);
    const partsAfterFirstParenthese = splits.splice(1);
    const parts = partsAfterFirstParenthese.map((part) => {
      const [partBetweenParentheses] = part.split(symbol2);
      return partBetweenParentheses;
    });
    return parts;
  };
}

export function roundToTwo(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
export function handleFieldType(item) {
  if (item.FIELDTYPE === 'Text') {
    if (item.FIELDPROPERTY === 'Integer') {
      return item.FIELDVALUE.replace(/\./g, '');
    }
    if (item.FIELDPROPERTY === 'Number') {
      return item.FIELDVALUE.replace(/\./g, '').replace(/,/g, '.');
    }
  }
  return item.FIELDVALUE;
}
