import { isObjEmpty } from './helpers';
import { getData, getSelectorStringData } from './services/services.utils';

/* ----------------- Search Data ------------------- */
const emptySearchRequest = (fieldName) => ({
  fieldName,
  success: true,
  data: { [fieldName]: [] },
});
function extractSearchData(responses) {
  return responses.reduce((acc, response) => {
    const { data, success, fieldName } = response;
    if (!success || !data || isObjEmpty(data)) return acc;
    const [[values]] = Object.values(data);
    if (!(fieldName in acc)) {
      return { ...acc, [fieldName]: [{ ...values }] };
    }
    return { ...acc, [fieldName]: [...acc[fieldName], { ...values }] };
  }, {});
}
function fillKeysWithValues(data, searchInfo) {
  return searchInfo.reduce((acc, searchData) => {
    const { table, field } = searchData;
    const softOneObject = data[table];
    if (!softOneObject) return acc;
    const searchInfoWithKeys = softOneObject.map((item) => {
      return { ...searchData, key: item[field] };
    });
    return [...acc, ...searchInfoWithKeys];
  }, []);
}
export async function getDataForSearchFields(editData, searchInfo) {
  if (!searchInfo.length) return {};
  const newSearchInfo = fillKeysWithValues(editData, searchInfo);
  const allSearchPromises = newSearchInfo.map((searchData) => {
    // dont send requests without a key
    if (!searchData.key) return emptySearchRequest(searchData.field);
    /* --------------------- */
    return getData({
      fieldName: searchData.field,
      key: searchData.key,
      form: searchData.form || '',
      object: searchData.object || '',
      locateInfo: `${searchData.object}:${searchData.locateInfo}`,
    });
  });
  const searchResults = await Promise.all(allSearchPromises);
  return extractSearchData(searchResults);
}

/* ----------------- String List ------------------- */
function extractStringListData(responses) {
  return responses.reduce((acc, response) => {
    const { columns, data, editor } = response;
    if (!columns || !data || !editor) return acc;
    return { ...acc, [editor]: { columns, data } };
  }, {});
}
function cleanStringList(editData, stringListInfo) {
  return stringListInfo.filter((stringListValue) => {
    const [object, name] = stringListValue.fieldName.split('.');
    if (!(object in editData)) return false;
    return Object.keys(editData[object][0]).some((key) => key === name);
  });
}
export async function getDataForStringListFields(editData, stringListInfo) {
  if (!stringListInfo.length) return {};
  const validStringList = cleanStringList(editData, stringListInfo);
  const allStringListPromises = validStringList.map((stringListValue) =>
    getSelectorStringData('', { editor: stringListValue.editor }),
  );
  const stringListResults = await Promise.all(allStringListPromises);
  return extractStringListData(stringListResults);
}
