import { Component } from 'react';

import ErrorPage from '../shared/Layouts/ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage
          title='Go to Home'
          message={this.state.error}
          handler={() => {
            this.setState({ error: null, errorInfo: null });
            window.location.href = '/';
          }}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
