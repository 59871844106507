import { NavLink, useHistory } from 'react-router-dom';

import { useUserConfiguration } from '../../config/themes';
import { useAuth, useAuthDispatch } from '../../context/AuthProvider';
import {
  NavbarContainer,
  NavLogo,
  NavLinkWrapper,
  LogoutIcon,
  NavContent,
  BranchWrapper,
  AccountIcon,
  LinkIcon,
  KeyIcon,
} from './NavBar.style';
import NavDropList from './NavDropList';
import NavMenu from './NavMenu';

export default function NavBar() {
  const history = useHistory();
  const authState = useAuth();
  const authSend = useAuthDispatch();

  const { logoPath, logoHeight } = useUserConfiguration();

  const { objects = [], user, branch } = authState.context;

  const isAuthorized = authState.matches('authorized');
  const isInitialized = authState.matches('authorized.initialized');

  function handleLogout() {
    authSend('LOGOUT');
    history.replace({ pathname: '/' });
  }

  return (
    <NavbarContainer>
      <NavContent>
        <NavLogo
          alt='app-logo'
          src={logoPath}
          logoHeight={logoHeight}
          onClick={() => {
            history.push('/');
          }}
        />
        <NavLinkWrapper>
          {isInitialized && (
            <>
              <NavLink exact to='/' activeClassName='activeLink'>
                Home
              </NavLink>
              {objects.map(({ MENUOPTIONS, OBJECTPATH, OBJECTNAME }, index) => {
                const menuOptionsWithIcon = MENUOPTIONS.map((opt) => ({
                  ...opt,
                  ICON: <LinkIcon />,
                }));
                return (
                  <NavDropList
                    key={index}
                    title={OBJECTNAME}
                    objectPath={OBJECTPATH}
                    menuOptions={menuOptionsWithIcon}
                  />
                );
              })}
            </>
          )}
          {isAuthorized && (
            <NavDropList
              icon={<AccountIcon />}
              title='Account'
              menuOptions={[
                {
                  TITLE: (
                    <BranchWrapper>
                      <p>
                        {user.username} ({branch.selected.REFIDNAME})
                      </p>
                      <span>{branch.selected.MODULENAME}</span>
                    </BranchWrapper>
                  ),
                },
                {
                  TITLE: (
                    <BranchWrapper>
                      <p>{branch.selected.COMPANYNAME}</p>
                      <span>{branch.selected.BRANCHNAME}</span>
                    </BranchWrapper>
                  ),
                },
                {
                  HIDDEN: branch.objs.length <= 1,
                  TITLE: 'Change Company',
                  PATH: 'branch-selection',
                  ICON: <LinkIcon />,
                },
                {
                  TITLE: 'Change Password',
                  PATH: 'change-password',
                  ICON: <KeyIcon />,
                },
                {
                  TITLE: 'Logout',
                  ICON: <LogoutIcon />,
                  onClick: handleLogout,
                },
              ]}
            />
          )}
        </NavLinkWrapper>
        <NavMenu {...{ user, branch, objects, handleLogout }} />
      </NavContent>
    </NavbarContainer>
  );
}
