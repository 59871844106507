import { createNewItem } from '../machines/shared/utils/edit';
import {
  getDataForSearchFields,
  getDataForStringListFields,
} from './getDataForSpecialFields';
import { createSpecialFieldsInfo } from './locateInfo';

function getLoginDataKeys(loginData) {
  return loginData.reduce((acc, curr) => {
    if (!curr.FIELDNAME) return acc;
    const [table, field] = curr.FIELDNAME.split('.');
    if (!(table in acc)) {
      return { ...acc, [table]: [{ [field]: curr.FIELDVALUE }] };
    }
    return { ...acc, [table]: [...acc[table], { [field]: curr.FIELDVALUE }] };
  }, {});
}

export async function fillConfigWithLoginData({ config, loginData }) {
  try {
    if (loginData.length === 0 || config.length === 0) return config;

    const loginDataKeys = getLoginDataKeys(loginData);
    const { searchInfo, stringListInfo } = createSpecialFieldsInfo(config);

    const dataForSearchFields = await getDataForSearchFields(loginDataKeys, searchInfo);
    const dataForStringListFields = await getDataForStringListFields(
      loginDataKeys,
      stringListInfo,
    );

    const eventData = {
      editData: loginDataKeys,
      dataForSpecialFields: { dataForSearchFields, dataForStringListFields },
    };
    return config.map((item) => {
      if (!item.FIELDNAME) return item;
      const [objectName, tableName] = item.FIELDNAME.split('.');

      if (!(objectName in loginDataKeys)) return item;
      const index = loginDataKeys[objectName].findIndex(
        (loginitem) => loginitem[tableName],
      );
      if (index < 0) return item;

      return createNewItem({ item, index, eventData });
    });
  } catch (error) {
    console.error('Error: ', error.message);
  }
}
