import { useState, useRef } from 'react';

import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { StyledArrowUp, StyledArrowDown, DropList, Label } from '../Navbar/NavBar.style';

const NavDropList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { menuOptions, title, objectPath, icon } = props;
  const linkActive = location.pathname.split('/').includes(props.objectPath);
  const [active, setActive] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setActive(false));

  const handleClickLink = (e) => {
    e.preventDefault();
    setActive(!active);
  };
  const navigate = (option) => {
    let type = '';
    let itemUrl;

    if (option.TYPE === 'LIST') {
      type = 'search';
    }
    if (option.TYPE === 'CREATE') {
      type = 'new';
    }

    if (option.PATH && objectPath) {
      itemUrl = `/${objectPath}/${type}/${option.PATH}`;
    } else if (option.PATH) {
      itemUrl = `/${option.PATH}`;
    } else {
      itemUrl = `/${objectPath}/${type}`;
    }
    //hard refresh when we are in the same route
    if (location.pathname === itemUrl) window.location.href = itemUrl;
    else history.push(itemUrl);
  };

  if (menuOptions?.length === 0) return null;
  return (
    <NavLink
      ref={ref}
      to={`${props}`}
      onClick={handleClickLink}
      activeClassName='activeLink'
      className={`${linkActive ? 'activeLink' : ''}`}
    >
      {icon}
      {title}
      {active ? <StyledArrowUp /> : <StyledArrowDown />}
      {active && (
        <DropList>
          {menuOptions.map((option, index) => {
            if (option.HIDDEN) return null;
            return (
              <Label
                key={index}
                hasIcon={Boolean(option.ICON)}
                onClick={() => {
                  if (option.onClick) {
                    return option.onClick();
                  }
                  navigate(option);
                }}
              >
                {option.TITLE}
                {option.ICON}
              </Label>
            );
          })}
        </DropList>
      )}
    </NavLink>
  );
};

export default NavDropList;
