import styled from 'styled-components/macro';

import { device } from '../../../config/device';

const Layout = styled.div`
  padding: 2rem 4rem;
  min-height: calc(100vh - 14rem);
  @media ${device.laptop} {
    padding: 3rem;
  }
  @media ${device.tablet} {
    padding: 2rem;
  }
  @media ${device.mobile} {
    padding: 1rem 0.5rem;
  }
`;

const Container = styled.div`
  max-width: 97%;
  width: 100rem;
  margin: 0rem auto;
`;

export { Layout, Container };
