import axios from 'axios';

import { PORTAL_API } from '../../../config/API';
import { fillConfigWithLoginData } from '../../../helpers/fillConfigWithLoginData';

function checkServerFailure({ data }) {
  if (data.success) return;
  throw new Error('Softone Issue with initializing application');
}

export const services = {
  gettingConfig: async () => {
    const configResult = await axios.post(PORTAL_API, {
      service: 'getConfig',
    });
    checkServerFailure(configResult);
    return configResult.data.data;
  },
  gettingObjects: async () => {
    const objectsResult = await axios.post(PORTAL_API, {
      service: 'getObjects',
    });
    checkServerFailure(objectsResult);
    return objectsResult.data.data.OBJECT;
  },
  gettingLoginData: async () => {
    const loginDataResult = await axios.post(PORTAL_API, {
      service: 'getLoginData',
    });
    checkServerFailure(loginDataResult);
    return loginDataResult.data.data;
  },
  gettingSpecialFields: async (ctx) => {
    const { config, loginData } = ctx;
    return await fillConfigWithLoginData({
      config,
      loginData,
    });
  },
  gettingBranchConfig: async () => {
    const response = await axios.post(PORTAL_API, {
      service: 'getCompanyConfig',
    });
    const responseData = response.data;
    if (!responseData.success) {
      console.log(responseData.error);
      return {};
    }
    return responseData.data[0];
  },
};
