import { getFileGroups, getFileTableNamesFromGroupsArray } from './helpers';

const FILE_FIELDS = ['NAME', 'SOFNAME', 'LNUM', 'LINENUM', 'CRTDATE', 'CODE'];

const createCategories = (config) => {
  return config.reduce((acc, item) => {
    if (!item.FIELDNAME || item.CALCULATESKIP) return acc;
    const [tableName, fieldName] = item.FIELDNAME.split('.');

    if (!(tableName in acc)) {
      return { ...acc, [tableName]: [fieldName] };
    }
    if (item.FIELDLEVEL === 'Detail') {
      if (!acc[tableName].includes('LINENUM')) {
        acc[tableName] = [...acc[tableName], 'LINENUM'];
      }
    }
    return { ...acc, [tableName]: [...acc[tableName], fieldName] };
  }, {});
};
const convertArrayToString = (categories) => {
  return Object.keys(categories).reduce((finalString, category) => {
    return `${finalString + category}:${categories[category].toString()};`;
  }, []);
};

const createSearchInfo = (searchFields) => {
  return searchFields.reduce((prev, cur) => {
    if (!cur.FIELDNAME) return prev;
    const [tableName, fieldName] = cur.FIELDNAME.split('.');
    return [
      ...prev,
      {
        key: '',
        object: cur.FIELDEDITOR,
        locateInfo: cur.FIELDDISPLAY,
        table: tableName,
        field: fieldName,
      },
    ];
  }, []);
};

function createFilesLocateInfo(object) {
  const fileGroups = getFileGroups(object.PAGES);
  const tablesNames = getFileTableNamesFromGroupsArray(fileGroups);
  const locateInfo = tablesNames.reduce((previousLocateInfo, currentTable) => {
    const currentTableLocateInfo = `${currentTable}:${FILE_FIELDS.join(',')};`;
    return previousLocateInfo + currentTableLocateInfo;
  }, '');
  return locateInfo;
}

const createStringListInfo = (stringListFields) => {
  return stringListFields.reduce((prev, cur) => {
    return [...prev, { editor: cur.FIELDEDITOR, fieldName: cur.FIELDNAME }];
  }, []);
};

const findSearchFields = (configById) => {
  return configById.filter((item) => item.FIELDTYPE === 'Search');
};
const findStringListFields = (configById) => {
  return configById.filter((item) => item.FIELDTYPE === 'String');
};

export function createLocateObject(config, object) {
  const categories = createCategories(config);
  const locateInfo = convertArrayToString(categories);

  const filesLocateInfo = createFilesLocateInfo(object);
  const specialFieldsInfo = createSpecialFieldsInfo(config);

  return { locateInfo: locateInfo + filesLocateInfo, ...specialFieldsInfo };
}

export function createSpecialFieldsInfo(config) {
  const searchFields = findSearchFields(config);
  const searchInfo = createSearchInfo(searchFields);

  const stringListFields = findStringListFields(config);
  const stringListInfo = createStringListInfo(stringListFields);

  return { searchInfo, stringListInfo };
}
