import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as Link } from '../../assets/icons/link.svg';
import { LinksWrapper } from './NavMenu.style';

function NavMenuLink({ object, closeMenu }) {
  const history = useHistory();
  const location = useLocation();

  const navigate = (option) => {
    const objectPath = object.OBJECTPATH;
    let type = '';
    let itemUrl;

    if (option.TYPE === 'LIST') {
      type = 'search';
    }
    if (option.TYPE === 'CREATE') {
      type = 'new';
    }

    if (option.PATH && objectPath) {
      itemUrl = `/${objectPath}/${type}/${option.PATH}`;
    } else if (option.PATH) {
      itemUrl = `/${option.PATH}`;
    } else {
      itemUrl = `/${objectPath}/${type}`;
    }
    //hard refresh when we are in the same route
    if (location.pathname === itemUrl) window.location.href = itemUrl;
    else history.push(itemUrl);
  };

  if (object.MENUOPTIONS?.length === 0) return null;
  return (
    <li>
      {object.OBJECTNAME}
      <LinksWrapper>
        {object.MENUOPTIONS.map((option, index) => {
          if (option.HIDDEN) return null;
          return (
            <div
              key={index}
              className='nav-link'
              onClick={() => {
                if (option.PATH) {
                  navigate(option);
                  closeMenu();
                }
              }}
            >
              {option.TITLE}
              {option.PATH && <Link className='nav-icon' />}
            </div>
          );
        })}
      </LinksWrapper>
    </li>
  );
}

export default NavMenuLink;
