import { assign } from 'xstate';

import { groupBy } from '../../../helpers/helpers';

export function updateConfigWithExtraFields(oldConfig, newConfig) {
  const configWithUpdatedFields = oldConfig.filter((item) => item.UPDATEDFIELDS);
  if (!configWithUpdatedFields.length) return newConfig;
  return newConfig.map((newItem) => {
    const refItem = configWithUpdatedFields.find((oldItem) => {
      const extraFieldsToUpdate = oldItem.UPDATEDFIELDS.split(',');
      return extraFieldsToUpdate.includes(String(newItem.FIELDID));
    });
    if (!refItem) return newItem;
    return {
      ...newItem,
      DISPLAYVALUE: refItem.DISPLAYVALUE,
      FIELDVALUE: refItem.FIELDVALUE,
    };
  });
}

export function createDetailGroups(config) {
  const groupByFieldlevel = groupBy('FIELDLEVEL');
  const groupByGroup = groupBy('PAGEGROUP');

  const details = groupByFieldlevel(config).Detail;
  if (!details) return [];
  return groupByGroup(details);
}
export function createDetails(config, stores = []) {
  const detailsGroups = createDetailGroups(config);
  return Object.keys(detailsGroups).map((key) => ({
    name: key,
    ready: false,
    store: stores[key],
    machineRef: undefined,
    config: detailsGroups[key],
  }));
}

export function assignFactory(key) {
  return assign((ctx, e) => {
    return { ...ctx, [key]: e.data };
  });
}

export function addDraftToConfig(config, draft) {
  return config.map((configItem) => {
    if (configItem.FIELDLEVEL === 'Detail') {
      const foundItem = draft.find((draftItem) => {
        return draftItem.FIELDID === configItem.FIELDID;
      });
      return foundItem || configItem;
    }
    return configItem;
  });
}
