import styled from 'styled-components/macro';

import { device } from '../../config/device';
import { useUserConfiguration } from '../../config/themes';
import { Version } from '../../config/Version';

const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  height: 4rem;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.tertiary};
  font-size: ${({ theme }) => theme.small};
  @media ${device.tablet} {
    justify-content: flex-start;
    padding-left: 2rem;
  }
`;
const BrandBox = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    font-size: ${({ theme }) => theme.large};
  }
`;
const VersionBox = styled.div`
  font-size: ${({ theme }) => theme.small};
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 2rem;
`;

function Footer() {
  const { title } = useUserConfiguration();
  return (
    <FooterContainer>
      <BrandBox>
        {title} 2020 <span>&copy;</span>
      </BrandBox>
      <VersionBox>Version:{Version}</VersionBox>
    </FooterContainer>
  );
}

export default Footer;
