import {
  FinalScreenContainer,
  ImageWrapper,
  ErrorIcon,
  StyledButton,
  InfoWrapper,
} from './FinalScreen.style';

const ErrorScreen = ({ message, handler, title }) => {
  return (
    <FinalScreenContainer>
      <ImageWrapper></ImageWrapper>
      <InfoWrapper>
        <ErrorIcon />
        <span>Failure</span>
        <p>{message}</p>
        <StyledButton onClick={handler}>{title}</StyledButton>
      </InfoWrapper>
    </FinalScreenContainer>
  );
};

export default ErrorScreen;
